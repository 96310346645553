<template>
	<v-row class="mx-0" align="center">
		<template v-for="(label, i) in new_val['tag']">
			<v-chip
				class="paragraph mx-1 elevation-2"
				:color="field.choices.find(t=> t.id === label.id ) ? field.choices.find(t=> t.id === label.id ).color : ''"
				text-color="white"
				small
			>
				{{ label.display_name }}
				<v-btn v-if="edit" cols="auto" style="margin-right: -10px;" class="ml-1 cursor-pointer" @click="deleteLabel(i)" :loading="loading" icon x-small>
					<v-icon color="white" size="14">mdi-close</v-icon>
				</v-btn>
				
			</v-chip>

		</template>
		<v-col class="pa-0">
			<v-tooltip top open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn
							v-if="editable"
							:color="new_val['tag'].length !== 0 ? 'primary' : 'cell'"
							@click="dialog = true"
							:icon="new_val['tag'].length !== 0"
							small
							:class="new_val['tag'].length === 0 ? 'rounded-lg':''"
							v-on="new_val['tag'].length !== 0 ? on: null"
					>
						<div class="caption-1 cursor-pointer mr-1" v-if="new_val['tag'].length === 0" @click="dialog = true">{{ $t('single_trad.Item.add_tags') }}</div>
						<v-icon size="16">{{ new_val['tag'].length > 0 ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
					</v-btn>
				</template>
				<span>{{ $t('single_trad.Dialog.tag_field') }}</span>
			</v-tooltip>
		</v-col>

		<Dialog v-model="dialog" :title="$t('single_trad.Dialog.tag_field')" @close="dialog = false; $set(new_val, 'tag', oldVal['tag'])">
			<template v-slot:content>
				<FieldGroup
						v-model="new_val"
						:fields="{tag: field}"
						mode="fast-edition"
						:path="path"
						:pathId="pathId"
						:pevents="pevents"
						@saved="e => { dialog = false; $emit('saved') }"
						@newItemCreated="(e) => $emit('newItemCreated', e)"
				/>
			</template>
		</Dialog>
	</v-row>
</template>

<script>
	export default {
		name: "TagField",
		props: ['value', 'field', 'pevents', 'path', 'pathId', 'oldVal', 'editable'],
		components: {
			Dialog: () => import('@/components/ui/Dialog.vue'),
			Button: () => import('@/components/ui/Button.vue'),
			FieldGroup: () => import('@/components/FieldGroup.vue')
		},
		data() {
			return {
				dialog: false,
				loading: false,
				edit: false
			}
		},
		computed: {
			new_val: {
				get(){
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		},
		methods: {
			deleteLabel(index){
				this.loading = true

				let obj = JSON.parse(JSON.stringify(this.new_val['tag']))
				obj.splice(index, 1)

				this.$wsc.patchItem(this.path, this.pathId, {tag: obj}, success => {
					this.new_val['tag'].splice(index, 1)
					this.loading = false
					this.$emit('saved')
				}, fail => {
					this.$store.dispatch('snackbar/error', this.$t('snackbar.ExtraFieldsGroup.deletedOne.fail'))
					this.loading = false
				})
			}
		}
	}
</script>

<style scoped>

</style>